import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index';
import { saveAs } from 'file-saver';

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

export default {
  name: 'GenerateDocTemplate',
  methods: {
    renderDoc(template, data, outputName = 'output') {
      loadFile(template, (
        error,
        content,
      ) => {
        if (error) {
          throw error;
        }
        const zip = new PizZip(content);
        const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true });
        doc.setData(data);
        try {
          doc.render();
        } catch {
          console.log(JSON.stringify({ error }));

          if (error.properties && error.properties.errors instanceof Array) {
            const errorMessages = error.properties.errors
              .map((e) => e.properties.explanation)
              .join('\n');
            console.log('errorMessages', errorMessages);
          }
          throw error;
        }
        const out = doc.getZip().generate({
          type: 'blob',
          mimeType:
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        });
        saveAs(out, `${outputName}.docx`);
      });
    },
  },
};
