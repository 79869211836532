/**
 * Вычисленные инициалы пользователя из полного имени, максимум 2 символа
 * @returns {string}
 */
export const userInitials = (username) => username
  .split(' ')
  .map((part) => part[0].concat('.'))
  .join('')
  .substr(2, 4)
  .toUpperCase();

/**
 * Получение фамилии с инициалами
 * @param value {string} - полное имя
 * @returns {string}
 */
export const nameWithInitials = (fullUserName) => {
  const items = fullUserName.split(' ');
  const lastName = items[0] || ' ';
  const firstName = (items[1] || ' ').split('')[0];
  const middleName = (items[2] || ' ').split('')[0];
  return `${lastName} ${firstName}.${middleName}.`;
};
