<template>
  <div class="dismissal-template" v-if="isShowed">
    <esmp-button
      view="outline"
      @click="renderDoc(statementPath, statementData, 'Заявление на увольнение')"
    >
      Создать шаблон заявления
    </esmp-button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';
import GenerateDocTemplate from '@/mixins/generateDocTemplate';
import FIELD_IDS from '@/constants/dismissal-template';
import { userInitials } from '@/utils/userInitials';
import { DEFAULT_MONTHS, GENITIVE_MONTHS } from '@/constants/date';
import { updateDayjsLocale } from '@/filters/dateFormat';

export default {
  name: 'DismissalTemplate',
  mixins: [GenerateDocTemplate],
  props: {
    checklist: {
      type: Array,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState('user', ['loggedInUser']),
    statementPath() {
      // eslint-disable-next-line global-require
      return require('@/assets/docs/StatementOfDismissal.docx')?.default;
    },
    dismissalId() {
      return process.env.VUE_APP_DISMISSAL_ID;
    },
    statementData() {
      return {
        appointmentName: this.loggedInUser.appointmentName,
        deptName: this.loggedInUser.deptName,
        company: this.loggedInUser.company,
        fullName: this.loggedInUser.fullName,
        employeeNumber: this.loggedInUser.employeeNumber,
        phone: this.value[FIELD_IDS[this.dismissalId].phone],
        dismissalDay: dayjs(this.value[FIELD_IDS[this.dismissalId].dismissalDate]).format('DD'),
        dismissalMonth: dayjs(this.value[FIELD_IDS[this.dismissalId].dismissalDate])
          .locale(ru)
          .format('MMMM'),
        dismissalYear: dayjs(this.value[FIELD_IDS[this.dismissalId].dismissalDate]).format('YYYY'),
        dismissalType: this.getListValue('dismissalType'),
        reason: this.reason,
        employmentHistory: this.employmentHistory,
        date: dayjs().format('DD.MM.YYYY'),
        shortName: `${this.loggedInUser.lastName} ${userInitials(this.loggedInUser.fullName)}`,
      };
    },
    reason() {
      if (this.value[FIELD_IDS[this.dismissalId].reason] === FIELD_IDS[this.dismissalId].isOtherReason
        && this.value[FIELD_IDS[this.dismissalId].otherReasonGroup]) {
        return this.value[FIELD_IDS[this.dismissalId].otherReasonGroup][FIELD_IDS[this.dismissalId].otherReason];
      }
      return this.getListValue('reason');
    },
    isElectronicEmploymentHistory() {
      return this.value[FIELD_IDS[this.dismissalId].employmentHistory]
        === FIELD_IDS[this.dismissalId].isElectronicEmploymentHistory;
    },
    employmentHistory() {
      if (this.isElectronicEmploymentHistory && this.value[FIELD_IDS[this.dismissalId].emailGroup]) {
        return this.value[FIELD_IDS[this.dismissalId].emailGroup][FIELD_IDS[this.dismissalId].email];
      }
      return '';
    },
    isShowed() {
      return this.value && this.value[FIELD_IDS[this.dismissalId].phone]
        && this.value[FIELD_IDS[this.dismissalId].dismissalDate]
        && this.getListValue('dismissalType')
        && this.reason
        && (this.isElectronicEmploymentHistory ? this.employmentHistory : true);
    },
  },
  methods: {
    getListValue(type) {
      const id = this.value[FIELD_IDS[this.dismissalId][type]];
      if (!id) return false;
      const list = this.checklist.find((el) => el.id === FIELD_IDS[this.dismissalId][type])?.listValues || [];
      return list?.find((i) => i.id === id)?.name;
    },
  },
  mounted() {
    updateDayjsLocale('ru', {
      months: GENITIVE_MONTHS,
    });
  },
  beforeDestroy() {
    updateDayjsLocale('ru', {
      months: DEFAULT_MONTHS,
    });
  },
};
</script>
