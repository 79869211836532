const FIELD_IDS = {
  3841: {
    phone: '87843',
    dismissalType: '87842',
    dismissalDate: '87845',
    employmentHistory: '87846',
    isElectronicEmploymentHistory: '161350903',
    emailGroup: '87847',
    email: '87848',
    reason: '87849',
    isOtherReason: '161350915',
    otherReasonGroup: '87850',
    otherReason: '87851',
  },
  2180: {
    phone: '72792956',
    dismissalType: '72792955',
    reason: '72792962',
    isOtherReason: '164878525',
    otherReasonGroup: '72792963',
    otherReason: '72792964',
    employmentHistory: '72792959',
    isElectronicEmploymentHistory: '164878513',
    emailGroup: '72792960',
    email: '72792961',
    dismissalDate: '72792958',
  },
};

export default FIELD_IDS;
